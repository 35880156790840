@use '_shared' as *;

:root {
  /*
 * Deprecated spacings
 */
  --spacing-1px: #{rem-convert(1px)};
  --spacing-01rem: 0.1rem; /* 1.6px */
  --spacing-2px: #{rem-convert(2px)};
  --spacing-026rem: 0.26rem; /* 4.16px*/
  --spacing-5px: #{rem-convert(5px)};
  --spacing-6px: #{rem-convert(6px)};
  --spacing-9px: #{rem-convert(9px)};
  --spacing-06rem: 0.6rem; /* 9.6px */
  --spacing-10px: #{rem-convert(10px)};
  --spacing-09rem: 0.9rem; /* 14.4px */
  --spacing-15px: #{rem-convert(15px)};
  --spacing-18px: #{rem-convert(18px)};
  --spacing-22px: #{rem-convert(22px)};
  --spacing-30px: #{rem-convert(30px)};
  --spacing-34px: #{rem-convert(34px)};
  --spacing-42px: #{rem-convert(42px)};
  --spacing-44px: #{rem-convert(44px)};
  --spacing-45px: #{rem-convert(45px)};
  --spacing-52px: #{rem-convert(52px)};
  --spacing-60px: #{rem-convert(60px)};
  --spacing-70px: #{rem-convert(70px)};
  --spacing-162px: #{rem-convert(162px)};
  --spacing-216px: #{rem-convert(216px)};
}
