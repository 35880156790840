@use '_shared' as *;

.heading-1 {
  @include heading-1;
}

.heading-2 {
  @include heading-2;
}

.heading-3 {
  @include heading-3;
}

.heading-4 {
  @include heading-4;
}

.heading-5 {
  @include heading-5;
}

.font-style-body {
  font-size: var(--font-size-16px);
  line-height: var(--line-height-28px);
  font-weight: var(--font-weight-regular);
}

.font-style-body-bold {
  font-size: var(--font-size-16px);
  line-height: var(--line-height-28px);
  font-weight: var(--font-weight-bold);
}

.font-style-label {
  @include font-style-label;
}

.font-style-search-field {
  font-size: var(--font-size-32px);
  line-height: var(--line-height-32px);
  font-weight: var(--font-weight-bold);

  &::placeholder {
    font-weight: var(--font-weight-bold);
  }
}

.heading-1 + p,
.heading-2 + p,
.heading-1 + .heading-3,
.heading-2 + .heading-4 {
  margin-top: var(--spacing-16px);
}

.heading-3 + p,
.heading-4 + p {
  margin-top: var(--spacing-8px);
}

table {
  &.table-striped {
    > tr:nth-child(odd),
    > tbody > tr:nth-child(odd) {
      background: var(--color-grey-50);
    }
  }

  &.table-padding {
    th,
    td {
      padding: var(--spacing-8px) var(--spacing-16px);
    }
  }
}

a {
  color: var(--color-primary-500);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}
