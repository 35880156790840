/*
 * Hides any content from the sighted while still letting screen readers access the content.
 * Heavily inspired by https://kittygiraudel.com/snippets/visually-hidden-component/
 */
@mixin visually-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
