@use '_shared' as *;

.container {
  position: relative;
  &.horizontal-space {
    @media screen {
      padding-left: $container-horizontal-padding;
      padding-right: $container-horizontal-padding;

      &.small {
        width: 100%;
        max-width: $container-small-max-width + ($container-horizontal-padding * 2);
      }

      &.medium {
        width: 100%;
        max-width: $container-medium-max-width + ($container-horizontal-padding * 2);
      }

      &.large {
        width: 100%;
        max-width: $container-large-max-width + ($container-horizontal-padding * 2);
      }

      &.full-width {
        width: 100%;
      }

      &.no-padding {
        padding: unset;
      }
    }
  }

  &.vertical-space {
    @media screen {
      padding-top: $container-small-vertical-padding;
      padding-bottom: $container-small-vertical-padding;

      @include breakpoint(medium) {
        padding-top: $container-medium-vertical-padding;
        padding-bottom: $container-medium-vertical-padding;
      }

      @include breakpoint(large) {
        padding-top: $container-large-vertical-padding;
        padding-bottom: $container-large-vertical-padding;
      }
    }

    @media print {
      padding-top: var(--spacing-16px);
      padding-bottom: var(--spacing-16px);
    }
  }

  @media screen {
    &.small {
      width: 100%;
      max-width: min(var(--main-content-width), #{$container-small-max-width});
    }

    &.medium {
      width: 100%;
      max-width: min(var(--main-content-width), #{$container-medium-max-width});
    }

    &.large {
      width: 100%;
      max-width: min(var(--main-content-width), #{$container-large-max-width});
    }
  }

  &.center {
    margin: 0 auto;
  }

  &.center-content {
    display: flex;
    justify-content: center;
  }

  &.color-white {
    background-color: var(--color-white-100);
  }

  &.color-grey {
    background-color: var(--color-grey-50);
  }

  > * {
    &[data-width='small'] {
      width: 100%;

      @include breakpoint(large) {
        max-width: $container-small-max-width;
      }
    }

    &[data-width='medium'] {
      width: 100%;

      @include breakpoint(large) {
        max-width: $container-medium-max-width;
      }
    }

    &[data-width='large'] {
      width: 100%;

      @include breakpoint(large) {
        max-width: $container-large-max-width;
      }
    }

    &[data-width='full-width'] {
      width: 100%;
    }

    &[data-align='left'] {
      margin-right: auto;

      > .flex {
        justify-content: flex-start;
      }
    }

    &[data-align='center'] {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }

    &[data-align='right'] {
      margin-left: auto;
      justify-content: flex-end;
    }

    &[data-text-align='left'] {
      text-align: left;
      justify-content: flex-start;

      > .flex {
        &.column {
          align-items: flex-start;
        }

        &:not(.column) {
          justify-content: flex-start;
        }
      }
    }

    &[data-text-align='center'] {
      text-align: center;

      > .flex {
        &.column {
          align-items: center;
        }

        &:not(.column) {
          justify-content: center;
        }
      }
    }

    &[data-text-align='right'] {
      text-align: right;

      > .flex {
        &.column {
          align-items: flex-end;
        }

        &:not(.column) {
          justify-content: flex-end;
        }
      }
    }
  }

  .container-row {
    @include breakpoint(small down) {
      flex-direction: column;

      > .container-column {
        width: unset !important;
      }
    }
  }

  &.border-radius {
    border-radius: var(--border-radius-default);
  }

  &.cover,
  &.contain {
    background-repeat: no-repeat;
    position: relative;
    background-position: center center;
  }

  &.cover {
    background-size: cover;
  }
  &.contain {
    background-size: contain;
  }
}
