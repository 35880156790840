// TODO MF2-6572 Add missing `@media(hover: hover)` queries

mat-accordion {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  overflow: hidden;
  padding: var(--spacing-4px);
  border-radius: var(--border-radius-small);

  .mat-expansion-panel,
  .mat-content {
    overflow: unset;
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: unset;
  }

  .mat-expansion-panel-spacing {
    margin: 0;
  }

  .no-items {
    display: none;
  }

  .no-items:only-child {
    display: block;
  }

  .mat-expansion-panel {
    &:first-of-type,
    &:last-of-type {
      border-radius: unset;
    }

    + mat-expansion-panel {
      border-top: var(--border-width-1px) solid var(--color-grey-50);
      margin-top: var(--spacing-4px);
      padding-top: var(--spacing-4px);
      overflow: hidden;
    }
  }

  .mat-expansion-panel-header {
    font-size: var(--font-size-16px);
    padding: var(--spacing-2px) var(--spacing-10px) var(--spacing-2px) 0;
    border-radius: var(--border-radius-small);

    &,
    &.mat-expanded {
      height: unset;
    }

    .expansion-panel-arrow {
      transition: transform var(--transition-duration-medium) var(--transition-method-soft-bezier);
    }

    &.mat-expanded .expansion-panel-arrow {
      transform: rotate(-180deg);
    }
  }

  .mat-expansion-panel-header-title {
    display: flex;
    justify-content: space-between;
    margin-right: unset;
    color: var(--app-text-color);
  }

  /**
   * Special colors and checkbox styling when populating an accordion with checkboxes
   */
  &.checkbox-accordion {
    .mat-expansion-panel:not(.mat-expanded)
      .mat-expansion-panel-header:not([aria-disabled='true']):has(
        app-checkbox[data-state='checked'],
        app-checkbox[data-state='semi-checked']
      ):not(:hover) {
      background-color: var(--color-primary-100);
      color: var(--app-text-color);
    }

    .mat-expansion-panel-header.mat-expanded,
    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
      color: var(--color-primary-500);
    }

    mat-expansion-panel-header {
      border-radius: var(--border-radius-small) !important;
    }

    mat-panel-title {
      color: inherit;
      padding: var(--spacing-8px) var(--spacing-16px);
    }

    mat-expansion-panel-header {
      padding: var(--spacing-2px) 0 !important;
    }

    .mat-expansion-panel-body {
      padding: var(--spacing-16px) var(--spacing-48px);

      > ul {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-16px);
      }
    }
  }
}

// Fix for expansion panels not starting in expanded state and instantly collapse
// Solution copied from here: https://github.com/angular/components/issues/11765
.ng-animating mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0rem;
  visibility: hidden;
}
