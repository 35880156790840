@use '_shared' as *;
@use 'design-tokens/design-tokens' as *;
@use 'variables-deprecated' as *;

:root {
  /*
   * Accessibility colors
   */
  --color-focus-visible-border-color: rgb(16, 16, 16);

  /*
   * Concept colors
   */
  /* App base colors */
  --app-text-color: var(--color-grey-900);
  --app-background-color: var(--color-white-100);

  /* Content types */
  --color-content-type-exercise-collection-rgb: var(--color-violet-500-rgb);
  --color-content-type-exercise-collection: rgb(var(--color-content-type-exercise-collection-rgb));
  --color-content-type-lesson-rgb: var(--color-orange-500-rgb);
  --color-content-type-lesson: rgb(var(--color-content-type-lesson-rgb));
  --color-content-type-training-context-rgb: var(--color-green-500-rgb);
  --color-content-type-training-context: rgb(var(--color-content-type-training-context-rgb));
  --color-content-type-training-course-rgb: var(--color-fuchsia-500-rgb);
  --color-content-type-training-course: rgb(var(--color-content-type-training-course-rgb));
  --color-content-type-activity-rgb: var(--color-blue-500-rgb);
  --color-content-type-activity: rgb(var(--color-content-type-activity-rgb));
  --color-content-type-worksheet-rgb: var(--color-ocean-600-rgb);
  --color-content-type-worksheet: rgb(var(--color-content-type-worksheet-rgb));
  --color-content-type-adaptive-training-rgb: var(--color-green-500-rgb);
  --color-content-type-adaptive-training: rgb(var(--color-content-type-training-context-rgb));

  /* Inline error messages */
  --color-inline-error-message: var(--color-wrong-500);

  /* Overlay */
  --overlay-color-rgb: 200, 200, 200;

  /* Progress */
  --color-progress-gradient-start: var(--color-wrong-500);
  --color-progress-gradient-stop: var(--color-correct-500);

  /* Operational notifications */
  --color-operational-notification-background: #ffd80c; // This color isn't in our palette, but should be used anyways

  /*
   * Font sizes
   */
  --font-size-12px: #{rem-convert(12px)};
  --font-size-14px: #{rem-convert(14px)};
  --font-size-16px: #{rem-convert(16px)};
  --font-size-20px: #{rem-convert(20px)};
  --font-size-24px: #{rem-convert(24px)};
  --font-size-32px: #{rem-convert(32px)};
  --font-size-40px: #{rem-convert(40px)};
  --font-size-44px: #{rem-convert(44px)};

  /*
   * Line Heights
   */
  --line-height-20px: #{rem-convert(20px)};
  --line-height-22px: #{rem-convert(22px)};
  --line-height-24px: #{rem-convert(24px)};
  --line-height-28px: #{rem-convert(28px)};
  --line-height-36px: #{rem-convert(36px)};
  --line-height-44px: #{rem-convert(44px)};
  --line-height-56px: #{rem-convert(56px)};

  /* BEGIN: TODO MF2-4977 this is due to other stuff */
  --line-height-09rem: 0.9rem; /* 14.4px */
  --line-height-32px: #{rem-convert(32px)};
  --line-height-64px: #{rem-convert(64px)};
  /* END: TODO MF2-4977 this is due to other stuff */

  /*
 * Font weights
 */
  --font-weight-black: 900;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-regular: 400;

  /*
   * Text lengths
   */
  --text-length-min: #{rem-convert(280px)};
  --text-length-max: #{rem-convert(584px)};

  /*
   * Component defaults
   */

  --assigned-assignment-item-width: #{rem-convert(215px)};
  --assigned-assignment-item-height: #{rem-convert(215px)};

  /* Toast messages */

  --toast-message-height: #{rem-convert(56px)};

  /* badges sizes */
  --badge-card-new-sticker-size: 35%; /* sticker size in figma 56px */
  --badge-card-new-sticker-size-non-scroller: 50%; /* sticker size in figma 56px  */
  --badge-card-width: #{rem-convert(128px)};

  --achievement-badge-card-container-width-non-scroller: #{rem-convert(138px)};
  --achievement-badge-card-container-width: #{rem-convert(160px)};
  --achievement-badge-card-container-height: #{rem-convert(304px)};
  --achievement-badge-xs: #{rem-convert(72px)};
  --achievement-badge-s: #{rem-convert(88px)};
  --achievement-badge-m: #{rem-convert(112px)};
  --achievement-badge-l: #{rem-convert(160px)};

  /* message */
  --error-message-container-background-color: var(--color-wrong-500);

  /* challenges*/
  --challenge-icon-size: #{rem-convert(60px)};
  --small-challenge-icon-size: #{rem-convert(45px)};

  /*
   * Sizes / dimensions
   */
  --container-width-max: #{$max-width-container-training-content};

  & {
    /*
     * Inner paddings
     */
    --inner-padding: var(--spacing-24px);

    @include breakpoint(smallplus) {
      --inner-padding: var(--spacing-32px);
    }

    @include breakpoint(large) {
      --inner-padding: var(--spacing-48px);
    }
  }

  & {
    $main-content-width-max: rem-convert(1400px);
    $main-content-width-max-plus-padding: $main-content-width-max * 1.1215;
    --main-content-width-max: #{$main-content-width-max};
    --main-content-width-max-plus-padding: calc(var(--main-content-width-max) * 1.1215);
    --main-content-width: 87.85vw;

    // The width of the whitespace at the left and right of the main content
    --main-content-whitespace: calc(calc(100vw - var(--main-content-width) - var(--scrollbar-width, 0px)) / 2);
    --main-content-whitespace-negative: calc(var(--main-content-whitespace) * -1);

    --main-grid-spacing: var(--spacing-24px);
    --main-grid-column-count: 12;
    --main-grid-column-width: calc(
      calc(var(--main-content-width) + var(--main-grid-spacing)) / var(--main-grid-column-count) - var(
          --main-grid-spacing
        )
    );

    @media (max-width: 375px) {
      --main-content-width: calc(100vw - var(--main-grid-spacing) * 2);
    }

    @media (min-width: $main-content-width-max-plus-padding) {
      --main-content-width: var(--main-content-width-max);
    }

    @media (max-width: 1440px) {
      --main-grid-spacing: var(--spacing-20px);
    }

    @media (max-width: 1366px) {
      --main-grid-spacing: var(--spacing-16px);
    }
  }

  /*
   * Modals
   */
  & {
    --modal-margin: var(--spacing-20px);
    --modal-margin-negative: var(--spacing-20px-negative);
    --modal-padding: var(--spacing-32px);
    --modal-padding-negative: var(--spacing-32px-negative);
    --modal-dismiss-button-spacing: var(--spacing-8px);
    --modal-overlay-color: rgba(var(--color-grey-900-rgb), 0.2);
    --modal-background-color: var(--color-white-100);
    --modal-border-radius: var(--border-radius-large);

    @include breakpoint(small) {
      --modal-padding: var(--spacing-48px);
      --modal-padding-negative: var(--spacing-48px-negative);
      --modal-dismiss-button-spacing: var(--spacing-16px);
    }
  }

  /*
   * Borders
   */
  & {
    --border-radius-small: #{rem-convert(4px)};
    --border-radius-default: #{rem-convert(8px)};
    --border-radius-large: #{rem-convert(16px)};
    --border-radius-xlarge: #{rem-convert(24px)};
    --border-color: var(--color-grey-50);

    /* BEGIN: TODO MF2-4977 other borders in use */
    --border-radius-6px: #{rem-convert(6px)};
    --border-radius-9px: #{rem-convert(9px)};
    --border-radius-10px: #{rem-convert(10px)};
    --border-radius-15px: #{rem-convert(15px)};
    --border-radius-20px: #{rem-convert(20px)};
    --border-radius-24px: #{rem-convert(24px)};
    --border-radius-32px: #{rem-convert(32px)};
    --border-radius-40px: #{rem-convert(40px)};
    --border-radius-64px: #{rem-convert(64px)};
    --border-width-half-pixel: #{rem-convert(0.5px)};
    --border-width-05px: #{rem-convert(0.5px)};
    --border-width-1px: #{rem-convert(1px)};
    --border-width-2px: #{rem-convert(2px)};
    --border-width-3px: #{rem-convert(3px)};
    --border-width-4px: #{rem-convert(4px)};
    --border-width-6px: #{rem-convert(6px)};
    --border-width-8px: #{rem-convert(8px)};
    /* END: TODO MF2-4977 other borders in use */
  }

  /*
   * Header
   */
  & {
    --header-height: #{rem-convert(56px)};
  }

  /*
   * Transitions
   */
  & {
    --transition-duration-fastest: 0.05s;
    --transition-duration-fast: 0.15s;
    --transition-duration-medium: 0.3s;
    --transition-duration-medium-slow: 0.45s;
    --transition-duration-slow: 0.9s;
    --transition-duration-very-slow: 1.2s;
    --transition-duration-cart-animation: 0.2s;
    --transition-duration-swipe: 0.75s;

    --transition-method-soft-bezier: cubic-bezier(0.4, 0, 0.2, 1); // Duplicated in `shared/animations/beziers.ts`
    --transition-method-bounce-soft: cubic-bezier(0.2, 0, 0.3, 1.4);
    --transition-method-bounce-medium: cubic-bezier(0.45, 1.4, 0.15, 1);
    --transition-method-bounce-hard: cubic-bezier(0.75, 1.5, 0.75, 1);

    // Transition method and timing for responsive changes, such as paddings and gaps
    --transition-responsive: var(--transition-duration-medium) var(--transition-method-soft-bezier);
  }

  /*
  * Box-shadows
  */
  & {
    --box-shadow-color: #00000029;
    --box-shadow-default: 0 #{rem-convert(2px)} #{rem-convert(6px)} rgba(var(--color-primary-500-rgb), 0.4);
    --box-shadow-inset-top: inset 0px 11px 8px -10px rgba(var(--color-primary-500-rgb), 0.4);
    --box-shadow-1: 0 #{rem-convert(2px)} #{rem-convert(8px)} rgba(0, 0, 0, 0.75);
    --box-shadow-2: 0 #{rem-convert(3px)} #{rem-convert(6px)} var(--box-shadow-color);
    --box-shadow-3: #{rem-convert(6px)} #{rem-convert(5px)} #{rem-convert(12px)} var(--box-shadow-color);
    --box-shadow-4: 0 #{rem-convert(2px)} #{rem-convert(4px)} var(--box-shadow-color);
    --box-shadow-5: 0 #{rem-convert(4px)} #{rem-convert(4px)} 0 rgba(0, 0, 0, 0.25);
    --box-shadow-inset: inset var(--box-shadow-4);

    @media (prefers-reduced-motion: reduce) {
      /* Reduce all transition durations to almost zero to prevent any visual animation while maintaining JS events and callbacks */
      --transition-duration-fastest: 0.01s;
      --transition-duration-fast: 0.01s;
      --transition-duration-medium: 0.01s;
      --transition-duration-medium-slow: 0.01s;
      --transition-duration-slow: 0.01s;
      --transition-duration-very-slow: 0.01s;
      --transition-duration-cart-animation: 0.01s;
      --transition-duration-swipe: 0.01s;
    }
  }
}
