@use 'sass:math';
@use 'cover' as *;

@mixin embed {
  position: relative;
  overflow: hidden;
  @include cover;

  &:before {
    display: block;
    content: '';
  }
}

@mixin aspect-ratio-1by1 {
  padding-top: math.percentage(math.div(1, 1));
}
// landscape
@mixin aspect-ratio-21by9 {
  padding-top: math.percentage(math.div(9, 21));
}

@mixin aspect-ratio-16by9 {
  padding-top: math.percentage(math.div(9, 16));
}

@mixin aspect-ratio-4by3 {
  padding-top: math.percentage(math.div(3, 4));
}

@mixin aspect-ratio-4by1 {
  padding-top: math.percentage(math.div(1, 4));
}
// portrait
@mixin aspect-ratio-9by21 {
  padding-top: math.percentage(math.div(21, 9));
}

@mixin aspect-ratio-9by16 {
  padding-top: math.percentage(math.div(16, 9));
}

@mixin aspect-ratio-3by4 {
  padding-top: math.percentage(math.div(4, 3));
}

@mixin aspect-ratio-1by4 {
  padding-top: math.percentage(math.div(4, 1));
}
