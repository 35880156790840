.clamp {
  // All browsers require the following properties to include the -webkit prefix, for some strange reason
  // https://caniuse.com/?search=line-clamp
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;

  &[data-line-clamp='2'] {
    -webkit-line-clamp: 2;
  }
  &[data-line-clamp='3'] {
    -webkit-line-clamp: 3;
  }
  &[data-line-clamp='4'] {
    -webkit-line-clamp: 4;
  }
  &[data-line-clamp='5'] {
    -webkit-line-clamp: 5;
  }
}
