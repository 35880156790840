@font-face {
  font-family: 'Nunito';
  src:
    url('/assets/fonts/Nunito-VariableFont_wght-subset.woff2') format('woff2'),
    url('/assets/fonts/Nunito-VariableFont_wght-subset.zopfli.woff') format('woff'),
    url('/assets/fonts/Nunito-VariableFont_wght-subset.ttf') format('truetype');
  font-weight: 400 900;
  font-style: normal;
  font-display: swap;
}
