@use 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @use 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@use 'scss/_shared/_shared';
@use 'scss/design-tokens/design-tokens';

@use 'scss/fonts';

@use 'scss/variables';
@use 'scss/reset';
@use 'scss/animations';
@use 'scss/typography';
@use 'scss/forms';
@use 'scss/grid';
@use 'scss/mat-overrides';
@use 'scss/_shared/components/_components';
@use 'scss/layout';
@use 'scss/print';
@use 'scss/a11y';

@use 'scss/mat/mat-accordion';

:root {
  font-family: 'Nunito', sans-serif;
  font-size: var(--font-size-16px);
  line-height: var(--line-height-28px);
}

.nonselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mat-calendar {
  width: 14rem;
  height: 17rem;
  background-color: var(--color-white-100);
  box-shadow: 2px 2px 4px rgb(223 223 223 / 40%);
}

/* FYI: these WS (white-space) classes originally was created for use in sections components used to show content from umbraco pages */
.ws-10 {
  height: var(--spacing-8px);
}
.ws-25 {
  height: var(--spacing-24px);
}
.ws-35 {
  height: var(--spacing-32px);
}
.ws-50 {
  height: var(--spacing-48px);
}

.flex {
  display: flex;
  &.wrap {
    flex-wrap: wrap;
  }
  &.grow {
    flex-grow: 1;
  }
  &.column {
    flex-direction: column;
  }
  &.jcsb {
    justify-content: space-between;
  }
}

.hidden {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

html {
  // Match the scroll padding with the main navigation bar to avoid overlapping when using JS to scroll to elements
  scroll-padding-top: var(--spacing-56px);
}

body {
  overflow-y: scroll;

  &:has(.embed-video-overlay) {
    overflow-y: clip;
  }

  &:has(.cdk-drag.cdk-drag-preview) {
    cursor: grabbing;
  }

  @media print {
    overflow: unset !important;
    background: transparent !important;
  }
}
