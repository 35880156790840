@use '_shared' as *;

input::placeholder {
  color: var(--color-grey-400);
  font-weight: var(--font-weight-regular);
}

.input-style-default {
  // The border color is assigned to a variable to allow reuse inside nested elements when using the default input style in components
  --input-border-color: var(--color-grey-300);

  color: var(--app-text-color);
  width: rem-convert(340px);
  padding: var(--padding-xsmall) var(--padding-small);
  font-weight: var(--font-weight-regular);
  border: var(--border-width-1px) solid var(--input-border-color);
  border-radius: var(--border-radius-default);
  background: var(--color-white-100);
  transition:
    all var(--transition-duration-fast) var(--transition-method-soft-bezier),
    padding var(--transition-responsive),
    border-color var(--transition-duration-medium) var(--transition-method-soft-bezier),
    background-color var(--transition-duration-medium) var(--transition-method-soft-bezier);

  &.full-width {
    width: 100%;
  }

  &::placeholder {
    color: var(--color-grey-400);
    font-weight: var(--font-weight-regular);
  }

  &:disabled {
    --input-border-color: var(--color-grey-100);

    color: var(--color-grey-100);
    background: var(--color-grey-05);
  }

  &:not([disabled]) {
    &[aria-owns],
    &:focus-visible,
    &:focus {
      --input-border-color: var(--color-primary-500);

      outline: none;

      &.correct {
        --input-border-color: var(--color-correct-300);
      }
      &.error {
        --input-border-color: var(--color-wrong-300);
      }
    }
  }

  &.correct {
    --input-border-color: var(--color-correct-100);

    background: var(--color-correct-50);

    &:focus {
      --input-border-color: var(--color-correct-300);
    }
  }

  &:invalid,
  &.error,
  &.ng-touched.ng-invalid {
    --input-border-color: var(--color-wrong-100);

    background: var(--color-wrong-50);

    &:focus {
      --input-border-color: var(--color-wrong-300);
    }
  }
}
