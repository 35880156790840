@use '_shared' as *;

.global-article-container {
  display: flex;

  @include breakpoint(smallplus down) {
    flex-direction: column;
  }

  .global-aside-container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-64px);
    gap: var(--spacing-48px);

    @include breakpoint(mediumplus down) {
      padding-top: 0;
    }

    .global-aside-section {
      display: flex;
      flex-direction: column;
    }
  }
}

.global-content-container {
  display: flex;
  padding: var(--spacing-64px);

  &.global-main-container {
    flex-direction: column;
    gap: var(--spacing-64px);
  }

  .global-content-section {
    display: flex;
    flex-direction: column;
  }
}
