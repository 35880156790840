@use '_shared' as *;

.hero {
  display: flex;
  width: 100%;
  color: var(--color-white-100);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen {
    background-color: rgb($header-background-initial);
    color: rgb($header-text-initial);

    @include breakpoint(medium down) {
      min-height: var(--spacing-80px);
    }

    @include breakpoint(mediumplus) {
      min-height: rem-convert(150px);
    }
  }

  > .cover {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    @include cover;
    background-repeat: no-repeat;
    position: relative;

    @media print {
      background-image: none !important;
    }
  }
}
