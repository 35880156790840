@use '_shared/mixins/breakpoints' as *;

$main-grid-column-count: 12;

.main-grid {
  display: grid;
  gap: var(--main-grid-spacing);
  grid-template-columns: repeat($main-grid-column-count, var(--main-grid-column-width));

  .sm-col,
  .smp-col,
  .md-col,
  .mdp-col,
  .lg-col,
  .xl-col {
    grid-column: span $main-grid-column-count;
  }

  @mixin grid-columns {
    // We're looping twice to make sure span rules never overrule start and end rules when used in conjunction
    @for $i from 1 through $main-grid-column-count {
      &-span-#{$i} {
        grid-column: span $i / span $i;
      }
    }
    @for $i from 1 through $main-grid-column-count {
      &-start-#{$i} {
        grid-column-start: $i;
      }
      &-end-#{$i} {
        grid-column-end: $i;
      }
    }
  }

  .sm-col {
    @include grid-columns;
  }

  .smp-col {
    @include breakpoint(smallplus up) {
      @include grid-columns;
    }
  }

  .md-col {
    @include breakpoint(medium up) {
      @include grid-columns;
    }
  }

  .mdp-col {
    @include breakpoint(mediumplus up) {
      @include grid-columns;
    }
  }

  .lg-col {
    @include breakpoint(large up) {
      @include grid-columns;
    }
  }

  .xl-col {
    @include breakpoint(largeplus up) {
      @include grid-columns;
    }
  }
}
