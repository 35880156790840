@use '_shared' as *;

.rte {
  ul,
  ol {
    // padding-left: $spacing-20;
    padding-left: var(--spacing-24px);
  }

  ul {
    list-style-type: disc;
  }

  a:has(img) {
    border-bottom: unset !important;

    &.js-hover {
      background-color: unset !important;
    }

    @media (hover: hover) {
      &:hover {
        background-color: unset !important;
      }
    }
  }

  a {
    // html.theme-light & {
    color: rgb($color-primary-background);
    border-bottom: var(--border-width-2px) solid rgb($color-primary-background);
    // }

    // html.theme-light &.js-hover,
    // html.desktop.theme-light
    @media (hover: hover) {
      &:hover {
        background-color: rgba($color-primary-background, 0.1);
      }
    }

    // html.theme-dark & {
    //   color: $color-white;
    //   border-bottom: var(--border-width-2px) solid $color-white;
    // }

    // html.theme-dark &.js-hover,
    // html.desktop.theme-dark &:hover {
    //   background-color: rgba($color-white, 0.1);
    // }
  }

  p + p {
    margin-top: var(--spacing-24px);
  }
}
