@use '../mixins/embed' as *;
@use '../mixins/absolute-fill' as *;

.embed {
  @include embed;
  background-color: var(--color-white-100);

  &.contain {
    background-size: contain;
    background-repeat: no-repeat;
    img,
    iframe,
    embed,
    object,
    video {
      object-fit: contain;
    }
  }

  img,
  iframe,
  embed,
  object,
  video {
    @include absolute-fill;
    border: 0;
  }

  img {
    object-fit: cover;
  }

  &.aspect-ratio-1by1:before {
    @include aspect-ratio-1by1;
  }
  // landscape
  &.aspect-ratio-21by9:before {
    @include aspect-ratio-21by9;
  }

  &.aspect-ratio-16by9:before {
    @include aspect-ratio-16by9;
  }

  &.aspect-ratio-4by3:before {
    @include aspect-ratio-4by3;
  }

  &.aspect-ratio-4by1:before {
    @include aspect-ratio-4by1;
  }
  // portrait
  &.aspect-ratio-9by21:before {
    @include aspect-ratio-9by21;
  }

  &.aspect-ratio-9by16:before {
    @include aspect-ratio-9by16;
  }

  &.aspect-ratio-3by4:before {
    @include aspect-ratio-3by4;
  }

  &.aspect-ratio-1by4:before {
    @include aspect-ratio-1by4;
  }
}
