@use '_shared' as *;

body {
  /**
   * Mat Menu
   */
  .mat-menu-panel {
    margin-top: var(--spacing-8px);
    box-shadow: var(--box-shadow-2);
    border-radius: var(--border-radius-default);
  }

  .mat-menu-content:not(:empty) {
    padding-top: unset;
    padding-bottom: unset;
  }

  /**
   * Mat Menu Cart
   */
  .mat-menu-panel.cart-panel {
    max-width: rem-convert(560px);
    width: 100vw;
  }

  @include breakpoint(small down) {
    .mat-menu-panel.cart-panel {
      max-width: none;
      max-height: unset;
      margin-top: 0;
      border-radius: 0;
    }
    .cart-backdrop {
      + .cdk-overlay-connected-position-bounding-box {
        inset: 0;

        > .cdk-overlay-pane {
          inset: 0;
        }
      }
    }
  }
}
