:root {
  /*
   * Primary
   */
  --color-primary-50: var(--color-blurple-50);
  --color-primary-100: var(--color-blurple-100);
  --color-primary-200: var(--color-blurple-200);
  --color-primary-300: var(--color-blurple-300);
  --color-primary-400: var(--color-blurple-400);
  --color-primary-500: var(--color-blurple-500);
  --color-primary-600: var(--color-blurple-600);
  --color-primary-700: var(--color-blurple-700);
  --color-primary-800: var(--color-blurple-800);
  --color-primary-900: var(--color-blurple-900);

  --color-primary-50-rgb: var(--color-blurple-50-rgb);
  --color-primary-100-rgb: var(--color-blurple-100-rgb);
  --color-primary-200-rgb: var(--color-blurple-200-rgb);
  --color-primary-300-rgb: var(--color-blurple-300-rgb);
  --color-primary-400-rgb: var(--color-blurple-400-rgb);
  --color-primary-500-rgb: var(--color-blurple-500-rgb);
  --color-primary-600-rgb: var(--color-blurple-600-rgb);
  --color-primary-700-rgb: var(--color-blurple-700-rgb);
  --color-primary-800-rgb: var(--color-blurple-800-rgb);
  --color-primary-900-rgb: var(--color-blurple-900-rgb);

  /*
   * Secondary
   */
  --color-secondary-50: var(--color-teal-50);
  --color-secondary-100: var(--color-teal-100);
  --color-secondary-200: var(--color-teal-200);
  --color-secondary-300: var(--color-teal-300);
  --color-secondary-400: var(--color-teal-400);
  --color-secondary-500: var(--color-teal-500);
  --color-secondary-600: var(--color-teal-600);
  --color-secondary-700: var(--color-teal-700);
  --color-secondary-800: var(--color-teal-800);
  --color-secondary-900: var(--color-teal-900);

  --color-secondary-50-rgb: var(--color-teal-50-rgb);
  --color-secondary-100-rgb: var(--color-teal-100-rgb);
  --color-secondary-200-rgb: var(--color-teal-200-rgb);
  --color-secondary-300-rgb: var(--color-teal-300-rgb);
  --color-secondary-400-rgb: var(--color-teal-400-rgb);
  --color-secondary-500-rgb: var(--color-teal-500-rgb);
  --color-secondary-600-rgb: var(--color-teal-600-rgb);
  --color-secondary-700-rgb: var(--color-teal-700-rgb);
  --color-secondary-800-rgb: var(--color-teal-800-rgb);
  --color-secondary-900-rgb: var(--color-teal-900-rgb);

  /*
   * Tertiary
   */
  --color-tertiary-50: var(--color-blue-50);
  --color-tertiary-100: var(--color-blue-100);
  --color-tertiary-200: var(--color-blue-200);
  --color-tertiary-300: var(--color-blue-300);
  --color-tertiary-400: var(--color-blue-400);
  --color-tertiary-500: var(--color-blue-500);
  --color-tertiary-600: var(--color-blue-600);
  --color-tertiary-700: var(--color-blue-700);
  --color-tertiary-800: var(--color-blue-800);
  --color-tertiary-900: var(--color-blue-900);

  --color-tertiary-50-rgb: var(--color-blue-50-rgb);
  --color-tertiary-100-rgb: var(--color-blue-100-rgb);
  --color-tertiary-200-rgb: var(--color-blue-200-rgb);
  --color-tertiary-300-rgb: var(--color-blue-300-rgb);
  --color-tertiary-400-rgb: var(--color-blue-400-rgb);
  --color-tertiary-500-rgb: var(--color-blue-500-rgb);
  --color-tertiary-600-rgb: var(--color-blue-600-rgb);
  --color-tertiary-700-rgb: var(--color-blue-700-rgb);
  --color-tertiary-800-rgb: var(--color-blue-800-rgb);
  --color-tertiary-900-rgb: var(--color-blue-900-rgb);

  /*
   * Accent
   */
  --color-accent-50: var(--color-ocra-50);
  --color-accent-100: var(--color-ocra-100);
  --color-accent-200: var(--color-ocra-200);
  --color-accent-300: var(--color-ocra-300);
  --color-accent-400: var(--color-ocra-400);
  --color-accent-500: var(--color-ocra-500);
  --color-accent-600: var(--color-ocra-600);
  --color-accent-700: var(--color-ocra-700);
  --color-accent-800: var(--color-ocra-800);
  --color-accent-900: var(--color-ocra-900);

  --color-accent-50-rgb: var(--color-ocra-50-rgb);
  --color-accent-100-rgb: var(--color-ocra-100-rgb);
  --color-accent-200-rgb: var(--color-ocra-200-rgb);
  --color-accent-300-rgb: var(--color-ocra-300-rgb);
  --color-accent-400-rgb: var(--color-ocra-400-rgb);
  --color-accent-500-rgb: var(--color-ocra-500-rgb);
  --color-accent-600-rgb: var(--color-ocra-600-rgb);
  --color-accent-700-rgb: var(--color-ocra-700-rgb);
  --color-accent-800-rgb: var(--color-ocra-800-rgb);
  --color-accent-900-rgb: var(--color-ocra-900-rgb);

  /*
   * Wrong
   */
  --color-wrong-50: var(--color-red-50);
  --color-wrong-100: var(--color-red-100);
  --color-wrong-200: var(--color-red-200);
  --color-wrong-300: var(--color-red-300);
  --color-wrong-400: var(--color-red-400);
  --color-wrong-500: var(--color-red-500);
  --color-wrong-600: var(--color-red-600);
  --color-wrong-700: var(--color-red-700);
  --color-wrong-800: var(--color-red-800);
  --color-wrong-900: var(--color-red-900);

  --color-wrong-50-rgb: var(--color-red-50-rgb);
  --color-wrong-100-rgb: var(--color-red-100-rgb);
  --color-wrong-200-rgb: var(--color-red-200-rgb);
  --color-wrong-300-rgb: var(--color-red-300-rgb);
  --color-wrong-400-rgb: var(--color-red-400-rgb);
  --color-wrong-500-rgb: var(--color-red-500-rgb);
  --color-wrong-600-rgb: var(--color-red-600-rgb);
  --color-wrong-700-rgb: var(--color-red-700-rgb);
  --color-wrong-800-rgb: var(--color-red-800-rgb);
  --color-wrong-900-rgb: var(--color-red-900-rgb);

  /*
 * Correct
 */
  --color-correct-50: #e7f3ef;
  --color-correct-100: #b4dace;
  --color-correct-200: #90c8b6;
  --color-correct-300: #5daf94;
  --color-correct-400: #3d9f80;
  --color-correct-500: #0d8760;
  --color-correct-600: #0c7b57;
  --color-correct-700: #096044;
  --color-correct-800: #074a35;
  --color-correct-900: #053928;
}
