@use '../../_shared/_shared' as *;

:root {
  --spacing-160px-negative: #{rem-convert(-160px)};
  --spacing-144px-negative: #{rem-convert(-144px)};
  --spacing-128px-negative: #{rem-convert(-128px)};
  --spacing-112px-negative: #{rem-convert(-112px)};
  --spacing-96px-negative: #{rem-convert(-96px)};
  --spacing-80px-negative: #{rem-convert(-80px)};
  --spacing-72px-negative: #{rem-convert(-72px)};
  --spacing-64px-negative: #{rem-convert(-64px)};
  --spacing-56px-negative: #{rem-convert(-56px)};
  --spacing-48px-negative: #{rem-convert(-48px)};
  --spacing-40px-negative: #{rem-convert(-40px)};
  --spacing-36px-negative: #{rem-convert(-36px)};
  --spacing-32px-negative: #{rem-convert(-32px)};
  --spacing-28px-negative: #{rem-convert(-28px)};
  --spacing-24px-negative: #{rem-convert(-24px)};
  --spacing-20px-negative: #{rem-convert(-20px)};
  --spacing-16px-negative: #{rem-convert(-16px)};
  --spacing-8px-negative: #{rem-convert(-8px)};
  --spacing-4px-negative: #{rem-convert(-4px)};

  --spacing-4px: #{rem-convert(4px)};
  --spacing-8px: #{rem-convert(8px)};
  --spacing-12px: #{rem-convert(12px)};
  --spacing-16px: #{rem-convert(16px)};
  --spacing-20px: #{rem-convert(20px)};
  --spacing-24px: #{rem-convert(24px)};
  --spacing-28px: #{rem-convert(28px)};
  --spacing-32px: #{rem-convert(32px)};
  --spacing-36px: #{rem-convert(36px)};
  --spacing-40px: #{rem-convert(40px)};
  --spacing-48px: #{rem-convert(48px)};
  --spacing-56px: #{rem-convert(56px)};
  --spacing-64px: #{rem-convert(64px)};
  --spacing-72px: #{rem-convert(72px)};
  --spacing-80px: #{rem-convert(80px)};
  --spacing-96px: #{rem-convert(96px)};
  --spacing-112px: #{rem-convert(112px)};
  --spacing-128px: #{rem-convert(128px)};
  --spacing-144px: #{rem-convert(144px)};
  --spacing-160px: #{rem-convert(160px)};
}
