.badge {
  display: inline-flex;
  align-items: center;
  border-radius: var(--border-radius-small);
  padding: 0 var(--spacing-8px);
  font-size: var(--font-size-12px);
  font-weight: var(--font-weight-bold);
  background-color: var(--color-white-100);
  color: var(--color-grey-400);

  > * + * {
    margin-left: var(--spacing-8px);
  }
}
