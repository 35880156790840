@keyframes fadeValidation {
  0% {
    background-color: var(--color-white-100);
    border-color: var(--border-color);
    color: inherit;
  }
  45% {
    background-color: var(--color-anim);
    border-color: transparent;
    color: inherit;
  }
  85% {
    background-color: var(--color-anim);
    border-color: transparent;
    color: inherit;
  }
  100% {
    background-color: var(--color-anim-end);
    border-color: var(--color-anim-end);
  }
}

@keyframes opacityZeroToOne {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
