$site-background: var(--color-background);
$site-text: var(--color-text);
$site-link: var(--color-link);

$color-primary-background: var(--color-primary-background);
$color-primary-text: var(--color-primary-text);

$color-secondary-background: var(--color-secondary-background);
$color-secondary-text: var(--color-secondary-text);

$header-background-initial: var(--header-background-initial);
$header-text-initial: var(--header-text-initial);
$header-background-scrolled: var(--header-background-scrolled);
$header-text-scrolled: var(--header-text-scrolled);

$line-clamp: var(--line-clamp);

:host {
  --line-clamp: 2;
}
