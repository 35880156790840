@use '_shared' as *;

@media print {
  app-header,
  app-skip-to-content-button,
  app-background-graphics,
  app-footer {
    display: none !important;
  }

  .global-content-container,
  .global-article-container .global-aside-container {
    padding: 0;
  }

  .global-article-container {
    border-radius: var(--border-radius-large);
    background-color: var(--color-white-100);
    margin-top: var(--spacing-32px);
    flex-direction: column;

    .global-main-container {
      width: 100%;
    }

    .global-aside-container {
      width: 100%;
      border-left: none;
      margin-top: var(--spacing-32px);
    }
  }
}
