// CSS resets from https://piccalil.li/blog/a-modern-css-reset/ (modified)

/* Box sizing rules */
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Remove default margin */
body,
menu,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;

  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

html {
  box-sizing: border-box;
}

/* Set core body defaults */
body {
  text-rendering: optimizeSpeed;
}

/* Reset headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: inherit;
}

/* Reset lists */
ul {
  list-style-type: none;
  padding: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button:not([disabled]),
[role='button']:not([aria-disabled='true']) {
  cursor: pointer;
}

/* Reset tables */
table {
  border-collapse: collapse;
}
th,
td {
  text-align: inherit;
}

// Make the hidden attribute work
html body *[hidden] {
  display: none;
}
