@mixin heading {
  font-weight: var(--font-weight-bold);
}
@mixin heading-1 {
  font-size: var(--font-size-40px);
  line-height: var(--line-height-56px);
  @include heading;
}

@mixin heading-2 {
  font-size: var(--font-size-32px);
  line-height: var(--line-height-44px);
  @include heading;
}

@mixin heading-3 {
  font-size: var(--font-size-24px);
  line-height: var(--line-height-36px);
  @include heading;
}

@mixin heading-4 {
  font-size: var(--font-size-20px);
  line-height: var(--line-height-24px);
  &:has(.clamp) {
    /* MF2-5084 due to clipping of text in basket dropdown cards */
    line-height: var(--line-height-28px);
  }
  @include heading;
}

@mixin heading-5 {
  font-size: var(--font-size-16px);
  line-height: var(--line-height-28px);
  @include heading;
}

@mixin font-style-label {
  font-size: var(--font-size-14px);
  line-height: var(--line-height-20px);
  font-weight: var(--font-weight-semibold);
}
