@use 'sass:list';
@use 'sass:math';
@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';

$breakpoint-small-height: 444px; /// <<<< NOTE THIS IS HEIGHT ON PURPOSE
$breakpoint-medium-height: 666px; /// <<<< NOTE THIS IS HEIGHT ON PURPOSE
$breakpoint-large-height: 999px; /// <<<< NOTE THIS IS HEIGHT ON PURPOSE
$breakpoint-large-plus-height: 1200px; /// <<<< NOTE THIS IS HEIGHT ON PURPOSE

// For high views, ie ipads in portrait
$breakpoint-portrait-min-aspect-ratio: '66/100';
$breakpoint-landscape-min-aspect-ratio: '100/66';

/* ******************************************************
BEGIN: WIDTH breakpoints
*/

$breakpoint-exercise-medium: 700px;

$top-menu-width-breakpoint: 1060px;

$breakpoint-tiny: 0;
$breakpoint-small: 375px;
$breakpoint-small-plus: 600px;
$breakpoint-medium: 820px;
$breakpoint-medium-plus: 992px;
$breakpoint-large: 1366px;
$breakpoint-large-plus: 1440px;

$breakpoints: (
  'tiny': $breakpoint-tiny,
  'small': $breakpoint-small,
  'smallplus': $breakpoint-small-plus,
  'medium': $breakpoint-medium,
  'mediumplus': $breakpoint-medium-plus,
  'large': $breakpoint-large,
  'largeplus': $breakpoint-large-plus,
);

/*
examples of using the width breakpoints above:

@include breakpoint(small only) {
    /// ...
 }

 @include breakpoint(medium down) {
   /// ...
 }

 @include breakpoint(large) {
   /// ...
 }

*/

/*
END: WIDTH breakpoints
****************************************************** */

@function strip-unit($kit-number) {
  @if meta.type-of($kit-number) == 'number' and not math.is-unitless($kit-number) {
    /* @return $kit-number / ($kit-number * 0 + 1); */
    @return math.div($kit-number, ($kit-number * 0 + 1));
  }

  @return $kit-number;
}

@function -zf-to-rem-convert($kit-value, $kit-base: null) {
  // Check if the value is a number
  @if meta.type-of($kit-value) != 'number' {
    @warn inspect($kit-value) + ' was passed to rem-convert(), which is not a number.';
    @return $kit-value;
  }

  // Transform em into rem if someone hands over 'em's
  @if math.unit($kit-value) == 'em' {
    $kit-value: strip-unit($kit-value) * 1rem;
  }

  // Calculate rem if units for $kit-value is not rem or em
  @if math.unit($kit-value) != 'rem' {
    /* $kit-value: strip-unit($kit-value) / strip-unit($kit-base) * 1rem; */
    $kit-value: math.div(strip-unit($kit-value), strip-unit($kit-base));
  }

  // Turn 0rem into 0
  @if $kit-value == 0rem {
    $kit-value: 0;
  }

  @return $kit-value;
}

@function to-list($value, $keep: 'both') {
  $keep: if(list.index('keys' 'values', $keep), $keep, 'both');

  @if meta.type-of($value) == 'map' {
    $keys: ();
    $values: ();

    @each $key, $val in $value {
      $keys: list.append($keys, $key);
      $values: list.append($values, $val);
    }

    @if $keep == 'keys' {
      @return $keys;
    } @else if $keep == 'values' {
      @return $values;
    } @else {
      @return zip($keys, $values);
    }
  }

  @return if(type-of($value) != 'list', ($value), $value);
}
@function -zf-bp-to-em($kit-value) {
  // Pixel and unitless values are converted to rems
  @if math.unit($kit-value) == 'px' or math.is-unitless($kit-value) {
    $kit-value: rem-calc($kit-value, $kit-base: 16px);
  }

  // Then the value is converted to ems
  @return strip-unit($kit-value) * 1em;
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $kit-values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $kit-base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$kit-global-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
@function rem-calc($kit-values, $kit-base: null) {
  $kit-rem-values: ();
  $kit-count: list.length($kit-values);

  // If no base is defined, defer to the global font size
  @if $kit-base == null {
    $kit-base: $kit-font-size-text;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if math.unit($kit-base) == '%' {
    $kit-base: ($kit-base / 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if math.unit($kit-base) == 'rem' {
    $kit-base: strip-unit($kit-base) * 16px;
  }

  @if $kit-count == 1 {
    @return -zf-to-rem-convert($kit-values, $kit-base);
  }

  @for $kit-i from 1 through $kit-count {
    $kit-rem-values: append($kit-rem-values, -zf-to-rem-convert(nth($kit-values, $kit-i), $kit-base));
  }

  @return $kit-rem-values;
}

// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

////
/// @group breakpoints
////

/// A list of named breakpoints. You can use these with the `breakpoint()` mixin to quickly create media queries.
/// @type Map

/// The largest named breakpoint in which to include print as a media type
/// @type Keyword
$kit-print-breakpoint: large !default;

$kit--zf-zero-breakpoint: small !default;

$kit--zf-breakpoints-keys: to-list($breakpoints, 'keys');

@if list.nth(map.values($breakpoints), 1) != 0 {
  @error 'The first key in the $breakpoints map must have a value of "0".';
} @else {
  $kit--zf-zero-breakpoint: list.nth(map.keys($breakpoints), 1);
}

/// All of the names in this list will be output as classes in your CSS, like `.small-12`, `.medium-6`, and so on. Each value in this list must also be in the `$breakpoints` map.
/// @type List
$breakpoint-classes: (small medium large) !default;

/// Generates a media query string matching the input value. Refer to the documentation for the `breakpoint()` mixin to see what the possible inputs are.
///
/// @param {Keyword|Number} $kit-val [small] - Breakpoint name, or px, rem, or em value to process.
@function breakpoint($kit-val: $kit--zf-zero-breakpoint) {
  // Size or keyword
  $kit-bp: list.nth($kit-val, 1);
  // Value for max-width media queries
  $kit-bp-max: 0;
  // Direction of media query (up, down, or only)
  $kit-dir: if(list.length($kit-val) > 1, list.nth($kit-val, 2), up);
  // Eventual output
  $kit-str: '';
  // Is it a named media query?
  $kit-named: false;

  // Orientation media queries have a unique syntax
  @if $kit-bp == 'landscape' or $kit-bp == 'portrait' {
    @return '(orientation: #{$kit-bp})';
  } @else if $kit-bp == 'retina' {
    @return '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
  }

  // Try to pull a named breakpoint out of the $breakpoints map
  @if meta.type-of($kit-bp) == 'string' {
    @if map.has-key($breakpoints, $kit-bp) {
      @if $kit-dir == 'only' or $kit-dir == 'down' {
        $kit-bp-max: -zf-map-next($breakpoints, $kit-bp);
      }

      $kit-bp: map.get($breakpoints, $kit-bp);
      $kit-named: true;
    } @else {
      $kit-bp: 0;
      @warn 'breakpoint(): "#{$kit-val}" is not defined in your $breakpoints setting.';
    }
  }

  // Convert any pixel, rem, or unitless value to em
  $kit-bp: -zf-bp-to-em($kit-bp);
  // Max value is 0.2px under the next breakpoint (0.02 / 16 = 0.00125).
  // Use a precision under 1px to support browser zoom, but not to low to avoid rounding.
  // See https://github.com/zurb/foundation-sites/issues/11313
  @if $kit-bp-max {
    $kit-bp-max: -zf-bp-to-em($kit-bp-max) - 0.00125;
  }

  // Conditions to skip media query creation
  // - It's a named breakpoint that resolved to "0 down" or "0 up"
  // - It's a numeric breakpoint that resolved to "0 " + anything
  @if $kit-bp > 0em or $kit-dir == 'only' or $kit-dir == 'down' {
    // `only` ranges use the format `(min-width: n) and (max-width: n)`
    @if $kit-dir == 'only' {
      // Only named media queries can have an "only" range
      @if $kit-named == true {
        // Only use "min-width" if the floor is greater than 0
        @if $kit-bp > 0em {
          $kit-str: $kit-str + '(min-width: #{$kit-bp})';

          // Only add "and" to the media query if there's a ceiling
          @if $kit-bp-max != null {
            $kit-str: $kit-str + ' and ';
          }
        }

        // Only use "max-width" if there's a ceiling
        @if $kit-bp-max != null {
          $kit-str: $kit-str + '(max-width: #{$kit-bp-max})';
        }
      } @else {
        @warn 'breakpoint(): Only named media queries can have an `only` range.';
      }
    }

    // `down` ranges use the format `(max-width: n)`
    @else if $kit-dir == 'down' {
      $kit-max: if($kit-named, $kit-bp-max, $kit-bp);

      // Skip media query creation if input value is exactly "0 down",
      // unless the function was called as "small down", in which case it's just "small only"
      @if $kit-named or $kit-bp > 0em {
        @if $kit-max != null {
          $kit-str: $kit-str + '(max-width: #{$kit-max})';
        }
      }
    }

    // `up` ranges use the format `(min-width: n)`
    @else if $kit-bp > 0em {
      $kit-str: $kit-str + '(min-width: #{$kit-bp})';
    }
  }

  @return $kit-str;
}

/// Wraps a media query around the content you put inside the mixin. This mixin accepts a number of values:
///  - If a string is passed, the mixin will look for it in the `$breakpoints` map, and use a media query there.
///  - If a pixel value is passed, it will be converted to an em value using `$kit-global-font-size` as the base.
///  - If a rem value is passed, the unit will be changed to em.
///  - If an em value is passed, the value will be used as-is.
///
/// If multiple values are passed, the mixin will generate a media query for each of them as described above.
/// Since the content is duplicated for each breakpoint, this mixin should only be used with properties that
/// change across breakpoints.
///
/// @param {Keyword|Number} $kit-values... - Breakpoint name or px/rem/em value to process.
///
/// @output If the breakpoint is "0px and larger", outputs the content as-is. Otherwise, outputs the content wrapped in a media query.
@mixin breakpoint($kit-values...) {
  @for $kit-i from 1 through list.length($kit-values) {
    $kit-value: list.nth($kit-values, $kit-i);
    $kit-str: breakpoint($kit-value);
    $kit-bp: list.index($kit--zf-breakpoints-keys, $kit-value);
    $kit-pbp: list.index($kit--zf-breakpoints-keys, $kit-print-breakpoint);

    $kit-old-zf-size: null;

    // Make breakpoint size available as a variable
    @if meta.global-variable-exists(-zf-size) {
      $kit-old-zf-size: $kit--zf-size;
    }
    $kit--zf-size: list.nth($kit-value, 1) !global; // get the first value to account for `only` and `down` keywords

    // If $kit-str is still an empty string, no media query is needed
    @if $kit-str == '' {
      @content;
    }

    // Otherwise, wrap the content in a media query
    @else {
      // For named breakpoints less than or equal to $kit-print-breakpoint, add print to the media types
      @if $kit-bp != null and $kit-bp <= $kit-pbp {
        @media print, screen and #{$kit-str} {
          @content;
        }
      } @else {
        @media screen and #{$kit-str} {
          @content;
        }
      }
    }

    $kit--zf-size: $kit-old-zf-size !global;
  }
}

/// Converts the breakpoints map to a URL-encoded string, like this: `key1=value1&key2=value2`. The value is then dropped into the CSS for a special `<meta>` tag, which is read by the Foundation JavaScript. This is how we transfer values from Sass to JavaScript, so they can be defined in one place.
/// @access private
///
/// @param {Map} $kit-map - Map to convert.
///
/// @returns {String} A string containing the map's contents.
@function -zf-bp-serialize($kit-map) {
  $kit-str: '';
  @each $kit-key, $kit-value in $kit-map {
    $kit-str: $kit-str + $kit-key + '=' + -zf-bp-to-em($kit-value) + '&';
  }
  $kit-str: str-slice($kit-str, 1, -2);

  @return $kit-str;
}

/// Find the next key in a map.
/// @access private
///
/// @param {Map} $kit-map - Map to traverse.
/// @param {Mixed} $kit-key - Key to use as a starting point.
///
/// @returns {Mixed} The value for the key after `$kit-key`, if `$kit-key` was found. If `$kit-key` was not found, or `$kit-key` was the last value in the map, returns `null`.
@function -zf-map-next($kit-map, $kit-key) {
  // Store the keys of the map as a list
  $kit-values: map.keys($kit-map);

  $kit-i: 0;

  // If the Key Exists, Get the index of the key within the map and add 1 to it for the next breakpoint in the map
  @if (map.has-key($kit-map, $kit-key)) {
    $kit-i: list.index($kit-values, $kit-key) + 1;
  }

  // If the key doesn't exist, or it's the last key in the map, return null
  @if ($kit-i > list.length($kit-map) or $kit-i == 0) {
    @return null;
  }
  // Otherwise, return the value
  @else {
    @return map.get($kit-map, list.nth($kit-values, $kit-i));
  }
}

/// Return a list of our named breakpoints less than $kit-key. Useful for dealing with
/// responsive gutters for the grid.
/// @access private
///
/// @param {String} $kit-key - Key to use as last breakpoint.
///
/// @returns {Array} The list of breakpoints up to and. If $kit-key is auto, returns breakpoints above the zero
@function -zf-breakpoints-less-than($kit-key) {
  $kit-list: ();
  $kit-found_key: false;

  @each $kit-name in $kit--zf-breakpoints-keys {
    @if ($kit-name == $kit-key) {
      $kit-found_key: true;
    }
    @if not $kit-found_key {
      $kit-list: append($kit-list, $kit-name);
    }
  }
  @return $kit-list;
}

/// Return a list of our named breakpoints less than $kit-key. Useful for dealing with
/// responsive gutters for the grid.
/// @access private
///
/// @param {String} $kit-breakpoint - a named or non-named breakpoint.
///
/// @returns {Array} The list of breakpoints up to and. If $kit-key is auto, returns breakpoints above the zero
@function -zf-closest-named-breakpoint($kit-breakpoint) {
  $kit-last: $kit--zf-zero-breakpoint;
  $kit-found: false;

  $kit-value: unitless-calc($kit-breakpoint, 1px);
  @each $kit-key, $kit-val in $breakpoints {
    @if not $kit-found {
      @if unitless-calc($kit-val) > $kit-value {
        $kit-found: true;
      } @else {
        $kit-last: $kit-key;
      }
    }
  }

  @return $kit-last;
}

/// Get a value for a breakpoint from a responsive config map or single value.
/// - If the config is a single value, return it regardless of `$kit-value`.
/// - If the config is a map and has the key `$kit-value`, the exact breakpoint value is returned.
/// - If the config is a map and does *not* have the breakpoint, the value matching the next lowest breakpoint in the config map is returned.
/// @access private
///
/// @param {Number|Map} $kit-map - Responsive config map or single value.
/// @param {Keyword} $kit-value - Breakpoint name to use.
///
/// @return {Mixed} The corresponding breakpoint value.
@function -zf-get-bp-val($kit-map, $kit-value) {
  // If the given map is a single value, return it
  @if type-of($kit-map) == 'number' {
    @return $kit-map;
  }

  // Check if the breakpoint name exists globally
  @if not map-has-key($breakpoints, $kit-value) {
    @if type-of($kit-value) == 'number' {
      $kit-value: -zf-closest-named-breakpoint($kit-value);
    } @else {
      @return null;
    }
  }
  // Check if the breakpoint name exists in the local config map
  @else if map-has-key($kit-map, $kit-value) {
    // If it does, just return the value
    @return map-get($kit-map, $kit-value);
  }
  // Otherwise, find the next lowest breakpoint and return that value
  @else {
    $kit-anchor: null;
    $kit-found: false;

    @each $kit-key, $kit-val in $breakpoints {
      @if not $kit-found {
        @if map-has-key($kit-map, $kit-key) {
          $kit-anchor: $kit-key;
        }
        @if $kit-key == $kit-value {
          $kit-found: true;
        }
      }
    }

    @return map-get($kit-map, $kit-anchor);
  }
}

/// Return the best breakpoint to use according to the calling context. It returns in order:
/// 1. the given `$kit-value` argument if it is not null.
/// 2. the global breakpoint context `$kit--zf-size` if it is not null (like if called inside then `breakpoint()` mixin)
/// 3. the given `$kit-default` argument.
/// @access private
///
/// @param {Keyword} $kit-value [null] - Breakpoint to use in priority if non-null.
/// @param {Keyword} $kit-default [null] - Breakpoint to use by default if no other value can be used.
///
/// @return {Keyword} The resolved breakpoint.
@function -zf-current-breakpoint($kit-value: null, $kit-default: null) {
  @if ($kit-value != null) {
    @return $kit-value;
  } @else if (variable-exists(-zf-size) and type-of($kit--zf-size) != 'number') and $kit--zf-size != null {
    @return $kit--zf-size;
  } @else {
    @return $kit-default;
  }
}

$kit-small-up: '';
$kit-small-only: '';

@if map.has-key($breakpoints, small) {
  $kit-small-up: screen;
  $kit-small-only: string.unquote('screen and #{breakpoint(small only)}');
}

$kit-medium-up: '';
$kit-medium-only: '';

@if map.has-key($breakpoints, medium) {
  $kit-medium-up: string.unquote('screen and #{breakpoint(medium)}');
  $kit-medium-only: string.unquote('screen and #{breakpoint(medium only)}');
}

$kit-large-up: '';
$kit-large-only: '';

@if map.has-key($breakpoints, large) {
  $kit-large-up: string.unquote('screen and #{breakpoint(large)}');
  $kit-large-only: string.unquote('screen and #{breakpoint(large only)}');
}

$kit-xlarge-up: '';
$kit-xlarge-only: '';

@if map.has-key($breakpoints, xlarge) {
  $kit-xlarge-up: unquote('screen and #{breakpoint(xlarge)}');
  $kit-xlarge-only: unquote('screen and #{breakpoint(xlarge only)}');
}

$kit-xxlarge-up: '';

@if map.has-key($breakpoints, xxlarge) {
  $kit-xxlarge-up: unquote('screen and #{breakpoint(xxlarge)}');
}
