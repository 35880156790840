@use '../../_shared/color-manipulation' as *;

:root {
  /*
   * White
   */
  --color-white-100: #ffffff;
  --color-white-100-rgb: 255, 255, 255;

  /*
   * Blurple
   */
  --color-blurple-50: #eff0fd;
  --color-blurple-100: #cecff8;
  --color-blurple-200: #b6b8f5;
  --color-blurple-300: #9598f1;
  --color-blurple-400: #8184ee;
  --color-blurple-500: #6165ea;
  --color-blurple-600: #585cd5;
  --color-blurple-700: #4548a6;
  --color-blurple-800: #353881;
  --color-blurple-900: #292a62;

  --color-blurple-50-rgb: #{hexToRawRGB(#eff0fd)};
  --color-blurple-100-rgb: #{hexToRawRGB(#cecff8)};
  --color-blurple-200-rgb: #{hexToRawRGB(#b6b8f5)};
  --color-blurple-300-rgb: #{hexToRawRGB(#9598f1)};
  --color-blurple-400-rgb: #{hexToRawRGB(#8184ee)};
  --color-blurple-500-rgb: #{hexToRawRGB(#6165ea)};
  --color-blurple-600-rgb: #{hexToRawRGB(#585cd5)};
  --color-blurple-700-rgb: #{hexToRawRGB(#4548a6)};
  --color-blurple-800-rgb: #{hexToRawRGB(#353881)};
  --color-blurple-900-rgb: #{hexToRawRGB(#292a62)};

  /*
   * Teal
   */
  --color-teal-50: #f4fdfd;
  --color-teal-100: #dcf8f9;
  --color-teal-200: #cbf5f7;
  --color-teal-300: #b4f0f3;
  --color-teal-400: #a5edf1;
  --color-teal-500: #8fe9ed;
  --color-teal-600: #82d4d8;
  --color-teal-700: #66a5a8;
  --color-teal-800: #4f8082;
  --color-teal-900: #3c6264;

  --color-teal-50-rgb: #{hexToRawRGB(#f4fdfd)};
  --color-teal-100-rgb: #{hexToRawRGB(#dcf8f9)};
  --color-teal-200-rgb: #{hexToRawRGB(#cbf5f7)};
  --color-teal-300-rgb: #{hexToRawRGB(#b4f0f3)};
  --color-teal-400-rgb: #{hexToRawRGB(#a5edf1)};
  --color-teal-500-rgb: #{hexToRawRGB(#8fe9ed)};
  --color-teal-600-rgb: #{hexToRawRGB(#82d4d8)};
  --color-teal-700-rgb: #{hexToRawRGB(#66a5a8)};
  --color-teal-800-rgb: #{hexToRawRGB(#4f8082)};
  --color-teal-900-rgb: #{hexToRawRGB(#3c6264)};

  /*
   * Blue
   */
  --color-blue-50: #ebf2fd;
  --color-blue-100: #c0d7f8;
  --color-blue-200: #a2c4f4;
  --color-blue-300: #77a9ef;
  --color-blue-400: #5d98ec;
  --color-blue-500: #347ee7;
  --color-blue-600: #2f73d2;
  --color-blue-700: #2559a4;
  --color-blue-800: #1d457f;
  --color-blue-900: #163561;

  --color-blue-50-rgb: #{hexToRawRGB(#ebf2fd)};
  --color-blue-100-rgb: #{hexToRawRGB(#c0d7f8)};
  --color-blue-200-rgb: #{hexToRawRGB(#a2c4f4)};
  --color-blue-300-rgb: #{hexToRawRGB(#77a9ef)};
  --color-blue-400-rgb: #{hexToRawRGB(#5d98ec)};
  --color-blue-500-rgb: #{hexToRawRGB(#347ee7)};
  --color-blue-600-rgb: #{hexToRawRGB(#2f73d2)};
  --color-blue-700-rgb: #{hexToRawRGB(#2559a4)};
  --color-blue-800-rgb: #{hexToRawRGB(#1d457f)};
  --color-blue-900-rgb: #{hexToRawRGB(#163561)};

  /*
   * Ocra
   */
  --color-ocra-50: #fef8ef;
  --color-ocra-100: #fdebcd;
  --color-ocra-200: #fce1b5;
  --color-ocra-300: #fbd394;
  --color-ocra-400: #faca7f;
  --color-ocra-500: #f9bd5f;
  --color-ocra-600: #e3ac56;
  --color-ocra-700: #b18643;
  --color-ocra-800: #896834;
  --color-ocra-900: #694f28;

  --color-ocra-50-rgb: #{hexToRawRGB(#fef8ef)};
  --color-ocra-100-rgb: #{hexToRawRGB(#fdebcd)};
  --color-ocra-200-rgb: #{hexToRawRGB(#fce1b5)};
  --color-ocra-300-rgb: #{hexToRawRGB(#fbd394)};
  --color-ocra-400-rgb: #{hexToRawRGB(#faca7f)};
  --color-ocra-500-rgb: #{hexToRawRGB(#f9bd5f)};
  --color-ocra-600-rgb: #{hexToRawRGB(#e3ac56)};
  --color-ocra-700-rgb: #{hexToRawRGB(#b18643)};
  --color-ocra-800-rgb: #{hexToRawRGB(#896834)};
  --color-ocra-900-rgb: #{hexToRawRGB(#694f28)};

  /*
   * Grey
   */
  --color-grey-05: #f4f5f6;
  --color-grey-10: #e9eaec;
  --color-grey-50: #cdcfd3;
  --color-grey-100: #b8bbc2;
  --color-grey-200: #a1a6b0;
  --color-grey-300: #8b929f;
  --color-grey-400: #747d8d;
  --color-grey-500: #5f697d;
  --color-grey-600: #48546c;
  --color-grey-700: #32405a;
  --color-grey-800: #1c2b49;
  --color-grey-900: #071839;

  --color-grey-05-rgb: #{hexToRawRGB(#f4f5f6)};
  --color-grey-10-rgb: #{hexToRawRGB(#e9eaec)};
  --color-grey-50-rgb: #{hexToRawRGB(#cdcfd3)};
  --color-grey-100-rgb: #{hexToRawRGB(#b8bbc2)};
  --color-grey-200-rgb: #{hexToRawRGB(#a1a6b0)};
  --color-grey-300-rgb: #{hexToRawRGB(#8b929f)};
  --color-grey-400-rgb: #{hexToRawRGB(#747d8d)};
  --color-grey-500-rgb: #{hexToRawRGB(#5f697d)};
  --color-grey-600-rgb: #{hexToRawRGB(#48546c)};
  --color-grey-700-rgb: #{hexToRawRGB(#32405a)};
  --color-grey-800-rgb: #{hexToRawRGB(#1c2b49)};
  --color-grey-900-rgb: #{hexToRawRGB(#071839)};

  /*
   * Red
   */
  --color-red-50: #faebec;
  --color-red-100: #f0c1c3;
  --color-red-200: #e9a3a6;
  --color-red-300: #e0787e;
  --color-red-400: #d95e65;
  --color-red-500: #d0353e;
  --color-red-600: #bd3138;
  --color-red-700: #94262c;
  --color-red-800: #721e22;
  --color-red-900: #57171a;

  --color-red-50-rgb: #{hexToRawRGB(#faebec)};
  --color-red-100-rgb: #{hexToRawRGB(#f0c1c3)};
  --color-red-200-rgb: #{hexToRawRGB(#e9a3a6)};
  --color-red-300-rgb: #{hexToRawRGB(#e0787e)};
  --color-red-400-rgb: #{hexToRawRGB(#d95e65)};
  --color-red-500-rgb: #{hexToRawRGB(#d0353e)};
  --color-red-600-rgb: #{hexToRawRGB(#bd3138)};
  --color-red-700-rgb: #{hexToRawRGB(#94262c)};
  --color-red-800-rgb: #{hexToRawRGB(#721e22)};
  --color-red-900-rgb: #{hexToRawRGB(#57171a)};

  /*
   * Green
   */
  --color-green-50: #f0f4ec;
  --color-green-100: #d0ddc3;
  --color-green-200: #bacda7;
  --color-green-300: #9ab67e;
  --color-green-400: #86a865;
  --color-green-500: #68923f;
  --color-green-600: #5f8539;
  --color-green-700: #4a682d;
  --color-green-800: #395023;
  --color-green-900: #2c3d1a;

  --color-green-50-rgb: #{hexToRawRGB(#f0f4ec)};
  --color-green-100-rgb: #{hexToRawRGB(#d0ddc3)};
  --color-green-200-rgb: #{hexToRawRGB(#bacda7)};
  --color-green-300-rgb: #{hexToRawRGB(#9ab67e)};
  --color-green-400-rgb: #{hexToRawRGB(#86a865)};
  --color-green-500-rgb: #{hexToRawRGB(#68923f)};
  --color-green-600-rgb: #{hexToRawRGB(#5f8539)};
  --color-green-700-rgb: #{hexToRawRGB(#4a682d)};
  --color-green-800-rgb: #{hexToRawRGB(#395023)};
  --color-green-900-rgb: #{hexToRawRGB(#2c3d1a)};

  /*
   * Violet
   */
  --color-violet-50: #f4f0fc;
  --color-violet-100: #ded2f6;
  --color-violet-200: #cebcf2;
  --color-violet-300: #b89dec;
  --color-violet-400: #aa8ae9;
  --color-violet-500: #956de3;
  --color-violet-600: #8863cf;
  --color-violet-700: #6a4da1;
  --color-violet-800: #523c7d;
  --color-violet-900: #3f2e5f;

  --color-violet-50-rgb: #{hexToRawRGB(#f4f0fc)};
  --color-violet-100-rgb: #{hexToRawRGB(#ded2f6)};
  --color-violet-200-rgb: #{hexToRawRGB(#cebcf2)};
  --color-violet-300-rgb: #{hexToRawRGB(#b89dec)};
  --color-violet-400-rgb: #{hexToRawRGB(#aa8ae9)};
  --color-violet-500-rgb: #{hexToRawRGB(#956de3)};
  --color-violet-600-rgb: #{hexToRawRGB(#8863cf)};
  --color-violet-700-rgb: #{hexToRawRGB(#6a4da1)};
  --color-violet-800-rgb: #{hexToRawRGB(#523c7d)};
  --color-violet-900-rgb: #{hexToRawRGB(#3f2e5f)};

  /*
   * Ocean
   */
  --color-ocean-50: #eaf8fd;
  --color-ocean-100: #beeaf7;
  --color-ocean-200: #9fe0f4;
  --color-ocean-300: #73d2ee;
  --color-ocean-400: #58c9eb;
  --color-ocean-500: #2ebce6;
  --color-ocean-600: #2aabd1;
  --color-ocean-700: #2185a3;
  --color-ocean-800: #19677f;
  --color-ocean-900: #134f61;

  --color-ocean-50-rgb: #{hexToRawRGB(#eaf8fd)};
  --color-ocean-100-rgb: #{hexToRawRGB(#beeaf7)};
  --color-ocean-200-rgb: #{hexToRawRGB(#9fe0f4)};
  --color-ocean-300-rgb: #{hexToRawRGB(#73d2ee)};
  --color-ocean-400-rgb: #{hexToRawRGB(#58c9eb)};
  --color-ocean-500-rgb: #{hexToRawRGB(#2ebce6)};
  --color-ocean-600-rgb: #{hexToRawRGB(#2aabd1)};
  --color-ocean-700-rgb: #{hexToRawRGB(#2185a3)};
  --color-ocean-800-rgb: #{hexToRawRGB(#19677f)};
  --color-ocean-900-rgb: #{hexToRawRGB(#134f61)};

  /*
   * Fuchsia
   */
  --color-fuchsia-50: #fcedf4;
  --color-fuchsia-100: #f7c7db;
  --color-fuchsia-200: #f3acca;
  --color-fuchsia-300: #ee86b2;
  --color-fuchsia-400: #ea6fa3;
  --color-fuchsia-500: #e54b8c;
  --color-fuchsia-600: #d0447f;
  --color-fuchsia-700: #a33563;
  --color-fuchsia-800: #7e294d;
  --color-fuchsia-900: #60203b;

  --color-fuchsia-50-rgb: #{hexToRawRGB(#fcedf4)};
  --color-fuchsia-100-rgb: #{hexToRawRGB(#f7c7db)};
  --color-fuchsia-200-rgb: #{hexToRawRGB(#f3acca)};
  --color-fuchsia-300-rgb: #{hexToRawRGB(#ee86b2)};
  --color-fuchsia-400-rgb: #{hexToRawRGB(#ea6fa3)};
  --color-fuchsia-500-rgb: #{hexToRawRGB(#e54b8c)};
  --color-fuchsia-600-rgb: #{hexToRawRGB(#d0447f)};
  --color-fuchsia-700-rgb: #{hexToRawRGB(#a33563)};
  --color-fuchsia-800-rgb: #{hexToRawRGB(#7e294d)};
  --color-fuchsia-900-rgb: #{hexToRawRGB(#60203b)};

  /*
   * Orange
   */
  --color-orange-50: #fff3e9;
  --color-orange-100: #fedaba;
  --color-orange-200: #fec899;
  --color-orange-300: #fdaf6b;
  --color-orange-400: #fd9f4e;
  --color-orange-500: #fc8722;
  --color-orange-600: #e57b1f;
  --color-orange-700: #b36018;
  --color-orange-800: #8b4a13;
  --color-orange-900: #6a390e;

  --color-orange-50-rgb: #{hexToRawRGB(#fff3e9)};
  --color-orange-100-rgb: #{hexToRawRGB(#fedaba)};
  --color-orange-200-rgb: #{hexToRawRGB(#fec899)};
  --color-orange-300-rgb: #{hexToRawRGB(#fdaf6b)};
  --color-orange-400-rgb: #{hexToRawRGB(#fd9f4e)};
  --color-orange-500-rgb: #{hexToRawRGB(#fc8722)};
  --color-orange-600-rgb: #{hexToRawRGB(#e57b1f)};
  --color-orange-700-rgb: #{hexToRawRGB(#b36018)};
  --color-orange-800-rgb: #{hexToRawRGB(#8b4a13)};
  --color-orange-900-rgb: #{hexToRawRGB(#6a390e)};
}
