@use '../../_shared/_shared' as *;

:root {
  --gap-xsmall: var(--spacing-2px);
  --gap-small: var(--spacing-4px);
  --gap-medium: var(--spacing-8px);
  --gap-large: var(--spacing-12px);
  --gap-xlarge: var(--spacing-20px);
  --gap-xxlarge: var(--spacing-32px);

  --gap-xsmall-negative: calc(var(--gap-xsmall) * -1);
  --gap-small-negative: calc(var(--gap-small) * -1);
  --gap-medium-negative: calc(var(--gap-medium) * -1);
  --gap-large-negative: calc(var(--gap-large) * -1);
  --gap-xlarge-negative: calc(var(--gap-xlarge) * -1);
  --gap-xxlarge-negative: calc(var(--gap-xxlarge) * -1);

  @include breakpoint($breakpoint-medium) {
    --gap-xsmall: var(--spacing-4px);
    --gap-small: var(--spacing-8px);
    --gap-medium: var(--spacing-12px);
    --gap-large: var(--spacing-16px);
    --gap-xlarge: var(--spacing-24px);
    --gap-xxlarge: var(--spacing-36px);
  }

  @include breakpoint($breakpoint-large) {
    --gap-xsmall: var(--spacing-8px);
    --gap-small: var(--spacing-12px);
    --gap-medium: var(--spacing-16px);
    --gap-large: var(--spacing-24px);
    --gap-xlarge: var(--spacing-32px);
    --gap-xxlarge: var(--spacing-40px);
  }
}
