@use '../../_shared/_shared' as *;

:root {
  --padding-xsmall: var(--spacing-4px);
  --padding-small: var(--spacing-8px);
  --padding-medium: var(--spacing-16px);
  --padding-large: var(--spacing-24px);
  --padding-xlarge: var(--spacing-24px);
  --padding-xxlarge: var(--spacing-24px);

  --padding-xsmall-negative: calc(var(--padding-xsmall) * -1);
  --padding-small-negative: calc(var(--padding-small) * -1);
  --padding-medium-negative: calc(var(--padding-medium) * -1);
  --padding-large-negative: calc(var(--padding-large) * -1);
  --padding-xlarge-negative: calc(var(--padding-xlarge) * -1);
  --padding-xxlarge-negative: calc(var(--padding-xxlarge) * -1);

  @include breakpoint($breakpoint-medium) {
    --padding-xsmall: var(--spacing-4px);
    --padding-small: var(--spacing-8px);
    --padding-medium: var(--spacing-16px);
    --padding-large: var(--spacing-32px);
    --padding-xlarge: var(--spacing-32px);
    --padding-xxlarge: var(--spacing-48px);
  }

  @include breakpoint($breakpoint-large) {
    --padding-xsmall: var(--spacing-8px);
    --padding-small: var(--spacing-16px);
    --padding-medium: var(--spacing-24px);
    --padding-large: var(--spacing-32px);
    --padding-xlarge: var(--spacing-48px);
    --padding-xxlarge: var(--spacing-64px);
  }
}
